@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  composes: marketplaceModalRootStyles from global;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* Style */
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    /* Style */
    background-color: var(--matterColorBright);
  }
}

.content {
  composes: marketplaceModalBaseStyles from global;

  /* Size */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Style */
    border: 1px solid var(--matterColorNegative);
    border-radius: var(--borderRadiusMedium);

    /* Size */
    width: 100%;
    max-width: 480px;
    margin-bottom: 12.5vh;
    padding: var(--modalAuthenticationPagePadding);
  }
}

.contentTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
}

.emailVerificationContent,
.profileImageContent {
  /* Layout */
  flex-basis: unset;
  align-items: center;
  text-align: center;

  @media (--viewportMedium) {
    padding: 48px 56px;
  }
}

/* ================ Header & Logo ================ */

.logoHeader {
  padding: 32px 24px 8px;

  @media (--viewportMedium) {
    padding: 38px 24px 46px;
  }
}

.logo {
  height: 24px;
  width: auto;
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    justify-content: flex-start;
    margin-top: 6px;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.loginForm,
.signupForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.bottomWrapper {
  composes: authenticationModalBottomWrapper from global;
}

.bottomWrapperButton {
  margin-bottom: 12px;
}

.bottomWrapperLink {
  text-decoration: underline;
  text-underline-position: auto;
  color: var(--matterColor);
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  composes: h1 from global;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;

  margin-top: 12px;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  @media (--viewportMedium) {
    margin-top: 18px;
  }
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
  color: var(--failColor);

  margin-bottom: -4px;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;

  color: var(--matterColorDark);
}

.confirmInfoText {
  margin-top: 12px;
  margin-bottom: 0;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.step {
  color: var(--matterColorAnti);
}

.attentionBox {
  /* Layout */
  padding: 16px;

  /* Style */
  background-color: var(--attentionColor);
  border-radius: var(--borderRadiusMedium);

  /* Margin */
  margin-bottom: -12px;
  margin-top: 28px;
}

.attentionText {
  margin-top: 0;
  margin-bottom: 0;
}

.signupSeparator {
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid var(--matterColorNegative);
}

.signupText {
  /* Font */
  font-size: 14px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  text-align: center;

  margin-bottom: 0;
}
